import React,{useEffect,useState} from 'react'
import Banner from '../components/desktop/Banner'
import MyNavbar from '../components/desktop/MyNavbar'
import Quote from '../components/desktop/Quote'
import Footer from '../components/desktop/Footer'
import '../sass/sassFiles/desktop/homepage.scss'
import '../sass/sassFiles/desktop/contactpage.scss'
import '../sass/sassFiles/mobile/contactpage.scss'
import '../sass/sassFiles/mobile/homepagemb.scss'
import Copyright from '../components/desktop/Copyright'
import OtherPageBanner from '../components/desktop/OtherPageBanner'
import ContactBar from '../components/desktop/ContactBar'
import MapSectionComponent from '../components/desktop/MapSectionComponent'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbarmb from '../components/mobile/Navbarmb'
const ContactPage = () => {
    useEffect(()=>{
  
    
        var navbar = document.getElementById("mynavbar");
        
        function myFunction() {
         
          if(window.pageYOffset > 99){
            navbar.classList.add("sticky");
          }else{
            navbar.classList.remove("sticky");
          }
       
        }
        window.onscroll = function() {myFunction()};
        
       
      },[])
      useEffect(() => {
        AOS.init({once: true});
      }, [])
      const [elm,setelm]=useState("")

  function func(elem){
    setelm(elem)
  }
  return (
    <>
        <div className="mobile contactpage">
        <Navbarmb/>
        <OtherPageBanner title="Contact"/>
        <ContactBar/>
        <MapSectionComponent mbheight="mbheight" mbcontainergap="10px"/>
        <Quote func={func} gap="gap"/>
        <Footer/>
        <Copyright/>
        </div>
        <div className="desktop">
            <MyNavbar/>
            <OtherPageBanner title="Contact"/>
            <ContactBar/>
            <MapSectionComponent/>
            <Quote func={func} gap="gap"/>
            <Footer/>
            <Copyright/>
        </div>
    </>
  )
}

export default ContactPage