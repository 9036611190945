import React from 'react'

const WhoWeAre = () => {
  return (
    <>
    <div className="whowearesection">
        <div className="myContainer">
        <h4>Welcome to our IT company! We are a team of highly skilled and passionate professionals who specialize in developing innovative technological solutions for businesses across various industries.At our company, we believe that technology can transform businesses and society. Therefore, we are dedicated to providing our clients with cutting-edge technology solutions that not only meet their needs but also exceed their expectations. <br/> <br/>

Our team is made up of experienced software engineers, designers, project managers, and quality assurance specialists who work together to ensure that our clients receive top-quality products and services. We strive for excellence in everything we do, and our commitment to quality is reflected in the long-term partnerships we have built with our clients.<br/> <br/></h4>

        </div>

    </div>
    </>
  )
}

export default WhoWeAre