import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link,NavLink } from 'react-router-dom';
import {Row,Col} from 'react-bootstrap'
const Navbarmb = () => {
  let activeStyle = {
    color:"#ED1C24"
  };

const handleonclick=()=>{
  
  
  var mynavbarnav = document.getElementById("mynavbarnav");
  mynavbarnav.classList.toggle('show');
  mynavbarnav.classList.toggle('navbar-white');
}

const handletoggler=()=>{
  var navtoggler = document.getElementById("nav-icon4");
  navtoggler.classList.toggle('open');
}
  return (
    <>
        <Navbar className='mynavbar mynavbarmb' id="mynavbarmini" expand="lg">
        <Container>
        <Navbar.Brand href="#home"><Link to="/"><img style={{width:"114px"}} src="./assets/images/homepage/logo.png" alt="" /></Link></Navbar.Brand>
        <Navbar.Toggle   aria-controls="basic-navbar-nav" className="navbar-toggle x collapsed" data-toggle="collapse" data-target="#navbar-collapse-x">
          <div className="div" id="nav-icon4" onClick={handletoggler}>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>

          </div>
        </Navbar.Toggle>
        </Container>
        

        <Navbar.Collapse id="basic-navbar-navmb">
            <Nav className="ms-auto" style={{marginTop:"8vh"}}>
            <Link className='nav-link' to="/">Home</Link>
            <Link className='nav-link' to="/aboutus">About</Link>
            <Link className='nav-link' to="/services">Services</Link>
            <Link className='nav-link' to="/portfolio">Portfolio</Link>
            <Link className='nav-link' to="/pricing">Pricing</Link>
            <Link className='nav-link' to="/blog">Blog</Link>
            <Link className='nav-link' to="/contact">Contact</Link>
            
            </Nav>
        </Navbar.Collapse>
        
        </Navbar>
     
    </>
  )
}

export default Navbarmb