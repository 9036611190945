import React from 'react'

const MediumSectionHeader = (props) => {
  return (
    <div className="mediumsectionheader">
        <h3>{props.head} <span style={{color:"#005EAE"}}>{props.head2?props.head2:""}</span></h3>
    </div>
  )
}

export default MediumSectionHeader