import React from 'react'

const PricingTable = () => {
  return (
    <>
        <div className="pricingtable myContainer">
        <table cellSpacing={5}>
        <thead>
            <tr>
            <th>Features</th>
            <th>Basic</th>
            <th>Standard</th>
            <th>Premium</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>Admission Management</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Student Management</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Attendance Management</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Fee Management</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Lesson Planning</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Academic Management</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Live Classes</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Homework Management</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Teacher & Employees Management</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Payroll Management</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Leave Management</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Exam & Result Management</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Front Office</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Certificates</td>            
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Accounting</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Inventory</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>            
            <td>Yes</td>
            </tr>
            <tr>
            <td>MIS & Reporting</td>            
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Asset Management</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>            
            <td>Yes</td>
            </tr>
            <tr>
            <td>Document Management</td>            
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Transportation Management</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Library Management</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Alumni Management</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>            
            <td>Yes</td>
            </tr>
            <tr>
            <td>Communication Management</td>            
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Online Payment</td>            
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Data Management</td>            
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Event & News Management</td>            
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Teacher Portal & Apps</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            <tr>
            <td>Student / Parent Portal & Apps</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>            
            <td>Yes</td>
            </tr>
            <tr>
            <td>Website API</td>
            <td style={{color:"red", fontWeight:"500"}}>No</td>
            <td>Yes</td>
            <td>Yes</td>
            </tr>
            
        </tbody>
        </table>
        </div>
    </>
  )
}

export default PricingTable