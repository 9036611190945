import React from 'react'

const BigSectionHeader = () => {
  return (
    <div className="bigsectionheader">
        <h3>Services</h3>
    </div>
  )
}

export default BigSectionHeader