import Homepage from "./pages/Homepage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactPage from "./pages/ContactPage";

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import AboutUsPage from "./pages/AboutUsPage";
import ScrollToTop from "./components/desktop/subComponents/ScrollToTop ";
import ServicesPage from "./pages/ServicesPage";
import PortfolioPage from "./pages/PortfolioPage";
import PricingPage from "./pages/PricingPage";
import BlogPage from "./pages/BlogPage";

function App() {
  return (
    <div className="App">
      {/* <Homepage/> */}
      <BrowserRouter>
        <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/aboutus" element={<AboutUsPage/>} />
        <Route path="/services" element={<ServicesPage/>} />
        <Route path="/portfolio" element={<PortfolioPage/>} />
        <Route path="/pricing" element={<PricingPage/>} />
        <Route path="/blog" element={<BlogPage/>} />
      </Routes>
    </BrowserRouter>
      
    </div>
  );
}

export default App;
