import React from 'react'
import { Button } from 'react-bootstrap'
import $ from 'jquery'
import { Link, useNavigate } from 'react-router-dom'
const CommonButton = (props) => {
    const navigate = useNavigate()


  const handleButtonClick=()=>{
    scrollToSection(props.oriref?props.oriref:null)
    
  }

  const scrollToSection =(elementRef)=>{
    window.scrollTo({
      top:elementRef.current.offsetTop,
      behavior:"smooth"
    })
  }
  return (
    <>
    <div className="commonbutton d-none d-lg-block">
        <Button  onClick={handleButtonClick} data-aos-delay="400" data-aos="fade-up" data-aos-duration="2000">  {props.text}</Button>
    </div>
    <div className="commonbutton d-block d-lg-none">
    <Link to="/contact"><Button data-aos-delay="400" data-aos="fade-up" data-aos-duration="2000"> {props.text}</Button></Link>
    </div>
    
    </>
  )
}

export default CommonButton