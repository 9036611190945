import React from 'react'

const OurMission = () => {
  return (
    <>
        <div className="ourmission">
            <div className="myContainer">
                <h4>
                At our IT company, our mission is to help businesses leverage the power of technology to drive growth, increase efficiency, and create a positive impact in the world.

                We believe that technology should be accessible, reliable, and easy to use. Therefore, we are committed to providing our clients with innovative, user-friendly, and cost-effective technology solutions that meet their unique needs and exceed their expectations.
                </h4>
                <h4>
                We understand that technology is constantly evolving, and we are dedicated to staying at the forefront of the latest developments in the field. By staying up-to-date with the latest trends and tools, we are able to deliver solutions that are not only effective but also future-proof.

In addition to providing excellent technology solutions, we also believe in building long-lasting relationships with our clients. We strive to create a culture of collaboration, transparency, and trust, and we are always looking for ways to improve our communication and customer service.
                </h4>
            </div>
        </div>
    </>
  )
}

export default OurMission