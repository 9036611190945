import React,{useEffect,useState} from 'react'
import Banner from '../components/desktop/Banner'
import MyNavbar from '../components/desktop/MyNavbar'
import Footer from '../components/desktop/Footer'
import '../sass/sassFiles/desktop/homepage.scss'
import '../sass/sassFiles/desktop/contactpage.scss'
import Copyright from '../components/desktop/Copyright'
import OtherPageBanner from '../components/desktop/OtherPageBanner'
import AOS from 'aos';
import 'aos/dist/aos.css';
import MediumSectionHeader from '../components/desktop/subComponents/MediumSectionHeader'
import WhoWeAre from '../components/desktop/aboutpage/WhoWeAre'
import '../sass/sassFiles/desktop/aboutpage.scss'
import OurMission from '../components/desktop/aboutpage/OurMission'
import Navbarmb from '../components/mobile/Navbarmb'
const AboutUsPage = () => {
    useEffect(()=>{
  
    
        var navbar = document.getElementById("mynavbar");
        
        function myFunction() {
         
          if(window.pageYOffset > 99){
            navbar.classList.add("sticky");
          }else{
            navbar.classList.remove("sticky");
          }
       
        }
        window.onscroll = function() {myFunction()};
        
       
      },[])
      useEffect(() => {
        AOS.init({once: true});
      }, [])
      const [elm,setelm]=useState("")

  function func(elem){
    setelm(elem)
  }
  return (
    <>
        <div className="mobile">
        
        <Navbarmb/>
            <OtherPageBanner title="About Us"/>
            <MediumSectionHeader head="Who" head2="We Are"/>
            <WhoWeAre/>
            <MediumSectionHeader head="Our" head2="Mission"/>
            <OurMission/>
            <Footer/>
            <Copyright/>
        </div>
        <div className="desktop">
            <MyNavbar/>
            <OtherPageBanner title="About Us"/>
            <MediumSectionHeader head="Who" head2="We Are"/>
            <WhoWeAre/>
            <MediumSectionHeader head="Our" head2="Mission"/>
            <OurMission/>
            <Footer/>
            <Copyright/>
        </div>
    </>
  )
}

export default AboutUsPage